import { Injectable } from '@angular/core';
import { AlgoliaClientProvider } from '@wizbii/algolia';
import { SearchIndex } from 'algoliasearch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AlgoliaService {
  constructor(private readonly algoliaClientProvider: AlgoliaClientProvider) {}

  getIndex(indexId: string): Observable<SearchIndex> {
    return this.algoliaClientProvider.getClient('main').pipe(map((client) => client.initIndex(indexId)));
  }
}
